import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import NavigationContext from "./Context/Context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./css/variable.css";
import "./css/style.css";
import "bootstrap/dist/css/bootstrap.css";
const el = document.getElementById("root");
const root = ReactDOM.createRoot(el);

root.render(
  <NavigationContext>
    <App />
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </NavigationContext>
);
