import axios from "axios";
import { getVariable } from "./Helpers/Session";

const apiRequest = async (apiurl, apiSetting = {}) => {
  let apiResult = "";
  const apiParams = apiSetting.apiParams ? apiSetting.apiParams : {};
  axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded;multipart/form-data";
  axios.defaults.headers.get["Content-Type"] = "application/json;charset=UTF-8";
  axios.defaults.timeout = 1000 * 60; // Wait for 60 seconds

  apiParams["lang_id"] = apiParams?.lang_id || "EN";
  apiParams["ws_debug"] = 1;
  const year_id = getVariable("yearId", true);
  const companyId = getVariable("companyId", true);
  const userId = getVariable("user");
  if (userId?.user_id) {
    apiParams["user_id"] = userId.user_id;
  }

  if (companyId) {
    apiParams["selected_company"] = companyId;
  }
  if (year_id) {
    apiParams["year_id"] = year_id;
  }
  const headerObj = {
    order_panel: 1,
    api_type: "mobile",
    viewer: 1,
  };
  const axiosConfig = {};
  const tokenObj = getVariable("token", true);

  if (tokenObj) headerObj["token"] = tokenObj;
  if (Object.keys(headerObj).length > 0) {
    axiosConfig["headers"] = headerObj;
  }

  if (apiSetting?.method && apiSetting.method === "POST") {
    let formData = new FormData();
    for (const [key, value] of Object.entries(apiParams)) {
      if (typeof value === "object")
        formData.append(key, value && JSON.stringify(value));
      else formData.append(key, value && value);
    }
    apiResult = await axios.post(apiurl, formData, axiosConfig);
  } else {
    axiosConfig["params"] = apiParams;
    apiResult = await axios.get(apiurl, axiosConfig);
  }
  return apiResult;
};

export default apiRequest;
