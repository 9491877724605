import { BrowserRouter as Router } from "react-router-dom";
import Routing from "./Routing";
import Header from "../../../components/Header";

function Layout(props) {
  return (
    <Router>
      <div className="page-container">
        <Header {...props} />
        <div className="page">
          <main className="main-content-area">
            <div className="container-fluid p-0">
              <div className="inner-container">
                <Routing {...props} />
              </div>
            </div>
          </main>
        </div>
      </div>
    </Router>
  );
}

export default Layout;
