import {
  getVariable,
  setVariable,
  removeVariable,
} from "../../Helpers/Session";
import { useState, useEffect, Fragment } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import Button from "../../components/Button";
import ListItem from "../../components/Listitem";
import apiRequest from "../../Api";
import ReactToast from "../../components/Toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
function PlaceOrder(props) {
  const [cartTotal, setCartTotal] = useState(0);
  const cartItems = getVariable("cartItems", true);
  const [userItems, setUserItems] = useState(cartItems);
  let selItems = getVariable("selItems", true);
  const [startDate, setStartDate] = useState(new Date());
  const curSymbol = getVariable("curSymbol", true);
  const history = useNavigate();
  useEffect(() => {
    if (!userItems || userItems.length <= 0) {
      history("/itemlist");
    }
    if (userItems) {
      calculateTotalAmt();
    }
  }, [userItems]);

  const onSearch = (event) => {
    let searchterm = event.target.value;
    if (searchterm) {
      const newItems = cartItems.filter((row, index) => {
        return row.itemTitle.toLowerCase().includes(searchterm.toLowerCase());
      });
      setUserItems(newItems);
    } else {
      setUserItems(cartItems);
    }
  };

  const calculateTotalAmt = (updateArr = []) => {
    let grandTotal = 0;
    let data_arr = updateArr.length > 0 ? updateArr : userItems;
    data_arr.map((value, index) => {
      grandTotal +=
        parseFloat(value.fUnitRate) *
        parseInt(value.cartQuantity ? value.cartQuantity : 1);
    });
    setCartTotal(grandTotal.toFixed(2));
  };

  const removeItem = async (event) => {
    const removedItem = event.target.getAttribute("data-pk-id");
    let indexOfItem = selItems.indexOf(removedItem);
    if (indexOfItem > -1) {
      selItems.splice(indexOfItem, 1);
      const updateItems = cartItems.filter((row, index) => {
        return row.pkid != removedItem;
      });

      setUserItems(updateItems);
      calculateTotalAmt(updateItems);
      setVariable("cartItems", updateItems, true);
      setVariable("selItems", selItems, true);
    }
  };

  const updateQty = (item_id, qty) => {
    let newgrandTotal = 0;
    cartItems.map((value, index) => {
      if (value.pkid == item_id) {
        value.cartQuantity = qty;
      }
      newgrandTotal +=
        parseFloat(value.fUnitRate) *
        parseInt(value.cartQuantity ? value.cartQuantity : 1);
    });
    setUserItems(cartItems);
    setVariable("cartItems", cartItems, true);
    setCartTotal(newgrandTotal.toFixed(2));
  };

  const keyPressEvent = (event) => {
    let keyCode = event.keyCode;

    if (
      !(keyCode >= 48 && keyCode <= 57) &&
      !(keyCode >= 96 && keyCode <= 105) &&
      !(keyCode == 8)
    ) {
      event.preventDefault();
    }
  };

  const saveOrderData = async (e) => {
    e.preventDefault();
    try {
      let baseUrl = process.env.REACT_APP_API_BASE;
      const apiSetting = {
        method: "POST",
        apiParams: {
          entry_type: "mobile",
          product_data: getVariable("cartItems", true),
          sele_date: moment(startDate).format("YYYY-MM-DD"),
        },
      };
      const orderData = await apiRequest(
        `${baseUrl}OrderPanelApi/saveOrderData`,
        apiSetting
      );
      if (orderData.data && orderData.data.success == 1) {
        removeVariable("selItems", true);
        removeVariable("cartItems", true);
        ReactToast("success", orderData.data.message);
        history("/thank-you");
      } else {
        throw new Error(orderData.data.message);
      }
    } catch (error) {
      ReactToast({ type: "error", message: error.message });
    } finally {
    }
  };

  return (
    <div className="product-listing-page">
      <div className="place-ord-search-wrap">
        <div className="fl search-input-box">
          <SearchBar onSearch={onSearch} />
        </div>
        <div className="fr backtoitemlist">
          <NavLink to="/itemlist">
            <i className="mdi mdi-24px mdi-arrow-left-bold-circle"></i>
          </NavLink>
        </div>
      </div>

      <DatePicker
        selected={startDate}
        dateFormat="MM/dd/yyyy"
        onChange={(date) => setStartDate(date)}
      />
      <ul className="product-list cart-products">
        {userItems.length > 0 &&
          userItems.map((item, index) => {
            return (
              <Fragment key={index}>
                <ListItem
                  {...item}
                  page="placeorder"
                  removeItem={removeItem}
                  updateQty={updateQty}
                  keyPressEvent={keyPressEvent}
                  qty={item.cartQuantity}
                />
              </Fragment>
            );
          })}
      </ul>
      <div className="page-footer">
        <div className="summary">
          <label>Cart Amount</label>
          <div className="total">
            {curSymbol} {cartTotal}
          </div>
        </div>
        <Button
          className="bg-blue-400 p-2 w-40 rounded text-white"
          title="Place Order"
          type="button"
          onClick={saveOrderData}
        />
      </div>
    </div>
  );
}
export default PlaceOrder;
