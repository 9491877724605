import { Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";

function BasicTable(props) {
  const tableHeaders = props.headers.map((value, key) => {
    return (
      <th className={value.className} key={key}>
        {value.label}
      </th>
    );
  });
  let tableData = (
    <tr className="text-center">
      <td colSpan="4">No record found..</td>
    </tr>
  );

  if (props?.data && props.data.length > 0) {
    tableData = props.data.map((value, key) => {
      const tableRows = props.headers.map((item, index) => {
        return (
          <td className={item.className} key={index}>
            {item?.addLink &&
            item.addLink.toLowerCase() == "yes" &&
            item.urlLink != undefined ? (
              <NavLink to={"/" + item.urlLink + item.renderLink(value)}>
                {item.render(value)}
              </NavLink>
            ) : (
              item.render(value)
            )}
          </td>
        );
      });

      return (
        <tr key={key} className="border-b">
          {tableRows}
        </tr>
      );
    });
  }

  return (
    <Table responsive striped bordered hover>
      <thead>
        <tr>{tableHeaders}</tr>
      </thead>
      <tbody>{tableData}</tbody>
    </Table>
  );
}

export default BasicTable;
