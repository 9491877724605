import { Route, Routes } from "react-router-dom";
import RoutesArr from "./RoutesArr";
import Login from "../Login/Login";
import NotFound from "../../../components/PageNotFound";
import { useNavigate } from "react-router-dom";
const Routing = (props) => {
  return (
    <div className="routing-div">
      <Routes>
        {RoutesArr.map((row, index) => {
          if (props.userProfile) {
            return (
              <Route
                key={index}
                exact
                path={row.path}
                element={<row.component {...props} />}
              />
            );
          } else {
            if (!row.isLoginRequired) {
              return (
                <Route
                  key={index}
                  exact
                  path={row.path}
                  element={<row.component {...props} />}
                />
              );
            } else {
              return (
                <Route
                  key={index}
                  exact
                  path="*"
                  element={<Login {...props} />}
                />
              );
            }
          }
        })}
      </Routes>
    </div>
  );
};
export default Routing;
