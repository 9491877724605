import { useState, useContext, useEffect, Fragment } from "react";
import { useForm } from "react-hook-form";
import apiRequest from "../../../Api";
import Button from "../../../components/Button";
import { GoEyeClosed, GoEye } from "react-icons/go";
import ReactToast from "../../../components/Toast";
import { useNavigate } from "react-router";
import { setVariable, getVariable, clearAll } from "../../../Helpers/Session";
import logo from "../../../asset/images/logo.png";
import { MdEmail, MdLock } from "react-icons/md";

function Login(props) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const history = useNavigate();
  useEffect(() => {
    if (
      (getVariable("user") || getVariable("user", true)) &&
      getVariable("companyId")
    ) {
      history("/itemlist");
    } else if (getVariable("user") || getVariable("user", true)) {
      history("/company");
    } else {
      clearAll();
    }
  }, []);

  const [showPassword, setShowPassword] = useState(false);
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [passwordEye, setPasswordEye] = useState(<GoEyeClosed />);
  const togglePassword = () => {
    setShowPassword(showPassword ? false : true);
    setPasswordEye(showPassword ? <GoEyeClosed /> : <GoEye />);
  };

  const loginAction = async (data) => {
    try {
      setIsApiCalling(true);
      const rememberMe = data.rememberMe;
      const url = process.env.REACT_APP_API_AUTH;
      const apiSetting = {
        method: "POST",
        apiParams: { email_id: data.email, password: data.password },
      };
      const response = await apiRequest(url, apiSetting);

      if (response.data && response.data.success == 1) {
        if (rememberMe) {
          setVariable("rememberMe", rememberMe, true);
          setVariable("user", response.data.data, true);
        } else {
          setVariable("user", response.data.data);
        }
        setVariable("token", response.data.token, true);
        props.setUserProfile(response.data.data);
        let route = "/company";
        if (response.data.data && response.data.data.list.length == 1) {
          setVariable("yearId", response.data.data.list[0]["id"], true);
          const yearData = response.data.data.list[0];
          let yarObj = { value: yearData["id"], label: yearData["years"] };
          setVariable("yearObj", yarObj, true);
          if (response.data.data.list[0]["company_list"].length == 1) {
            setVariable(
              "companyId",
              response.data.data.list[0]["company_list"][0]["id"],
              true
            );
            const company = response.data.data.list[0]["company_list"][0];
            setVariable(
              "companyObj",
              { value: company["id"], label: company["companyName"] },
              true
            );
            let baseUrl = process.env.REACT_APP_API_BASE;
            const apiSetting = {
              method: "POST",
              apiParams: { method: "posSaleView" },
            };
            const itemData = await apiRequest(
              `${baseUrl}OrderPanelApi/index`,
              apiSetting
            );
            if (itemData.data.success == 1) {
              setVariable("itemsArr", itemData.data.data.items_data.data, true);
              setVariable("curSymbol", itemData.data.data.cur_symbol, true);
              setVariable("companyLogo", itemData.data.data.company_logo, true);
              history("/itemlist");
            } else {
              throw new Error(itemData.data.message);
            }
            route = "/itemlist";
          }
        }
        history(route);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      ReactToast({ type: "error", message: error.message });
    } finally {
      setIsApiCalling(false);
    }
  };
  return (
    <Fragment>
      <div className="login-page">
        <div className="brang-logo">
          <img src={logo} alt="logo" />
        </div>
      </div>
      <div className="login-screen">
        <h1>
          Login <em>Enter your email and password</em>
        </h1>
        <form
          className="login-form"
          onSubmit={handleSubmit(loginAction)}
          method="post"
        >
          <div className="login-form">
            <div className="form-row">
              <label htmlFor="userEmail">Email Address</label>
              <div className="form-input">
                <span className="input-icon">
                  <i>
                    <MdEmail />
                  </i>
                </span>
                <input
                  type="email"
                  id="userEmail"
                  className="with-icon-input"
                  placeholder="Email address"
                  name="email"
                  autoFocus
                  {...register("email", {
                    required: "Enter your Email address",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Enter a valid e-mail address",
                    },
                  })}
                />
              </div>
              <div className="input-err">{errors?.email?.message}</div>
            </div>
            <div className="form-row">
              <label htmlFor="inputPassword">Password</label>
              <div className="form-input">
                <span className="input-icon">
                  <i>
                    <MdLock />
                  </i>
                </span>
                <i onClick={togglePassword} className="view-pass">
                  {passwordEye}
                </i>
                <input
                  type={showPassword ? "text" : "password"}
                  id="inputPassword"
                  className="with-icon-input"
                  placeholder="Password"
                  name="password"
                  {...register("password", {
                    required: "Enter your password",
                    minLength: {
                      value: 5,
                      message: "Minimum 5 characters",
                    },
                  })}
                />
              </div>
              <div className="input-err text-red-600">
                {errors?.password?.message}
              </div>
            </div>
            <div className="action-link text-center">
              <Button
                className="bg-blue-400 p-2 w-40 rounded text-white"
                title="Login"
                type="submit"
                disabled={isApiCalling}
              />
            </div>
          </div>
        </form>
      </div>
    </Fragment>
  );
}
export default Login;
