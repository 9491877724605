import { FaSistrix } from "react-icons/fa";
function SearchBar(props) {
  return (
    <div className="search-cnt">
      <FaSistrix className="search-icon" />

      <input
        type="text"
        placeholder="Search"
        className="form-control p-2 border rounded w-96 input-search"
        onChange={props.onSearch}
      />
    </div>
  );
}

export default SearchBar;
