import { Fragment } from "react";
import RoutsArr from "../pages/LoginNotRequired/Layout/RoutesArr";
import { NavLink } from "react-router-dom";
import { getVariable } from "../Helpers/Session";
function NavBar(props) {
  let user = getVariable("user");
  let companyId = getVariable("companyId", true);
  return (
    <Fragment>
      {user && companyId ? (
        <div className="user-logged-in">
          <input id="toggle" type="checkbox"></input>
          <label htmlFor="toggle" className="hamburger">
            <div className="top-bun"></div>
            <div className="meat"></div>
            <div className="bottom-bun"></div>
          </label>

          <div className="nav">
            <div className="nav-wrapper">
              <nav>
                {RoutsArr &&
                  RoutsArr.map((row, index) => {
                    if (user && row.isMenuItem == "yes") {
                      return (
                        <Fragment key={index}>
                          <NavLink
                           className={row.className}
                            to={row.path}
                            onClick={(e) => {
                              document.getElementById("toggle").click();
                            }}
                          >
                            {row.title}
                          </NavLink>
                          
                        </Fragment>
                      );
                    } else {
                      if (!row.isLoginRequired && row.isMenuItem == "yes") {
                        return (
                          <Fragment key={index}>
                            <NavLink to={row.path}>{row.title}</NavLink>
                            <br />
                          </Fragment>
                        );
                      }
                    }
                  })}

                {user ? (
                  <NavLink className="logout-link" to="/login" onClick={props.logout}>
                    Log Out
                  </NavLink>
                ) : (
                  <NavLink to="/login">Log In</NavLink>
                )}

                <br />
              </nav>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Fragment>
  );
}

export default NavBar;
