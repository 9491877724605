import { useEffect, useState } from "react";
import { BiPlusCircle, BiMinusCircle } from "react-icons/bi";
import { RxCrossCircled } from "react-icons/rx";
import { getVariable } from "../Helpers/Session";

function ListItem(props) {
  const [itemInQty, setitemInQty] = useState(
    props?.cartQuantity ? props.cartQuantity : 1
  );
  const [checked, setChecked] = useState(
    props && props.checked ? props.checked : false
  );
  const curSymbol = getVariable("curSymbol", true);
  useEffect(() => {
    if (props.page == "placeorder" || props.page == "returnorder") {
      setitemInQty(props.cartQuantity);
    }
  });
  return (
    <li className="">
      <div className="prod-img">
        <img
          src={props.product_image}
          alt="image name"
          className="product_image"
        />
      </div>
      <div className="about-prod">
        <div className="prod-name-unit">
          <h4>{props.itemTitle}</h4>
          {props.unitMasterName && (
            <div className="extra-info">Rate in {props.unitMasterName}</div>
          )}
        </div>
      </div>
      <div className="prod-price  text-right">
        {props.page == "itemlist" || props.page == "returnitemlist" ? (
          <div className="action-cnt">
            <label className="custom-checkbox">
              <input
                type="checkbox"
                data-pk-id={props.pkid}
                name={"checkbox[" + props.pkid + "]"}
                checked={checked}
                onChange={(event) => {
                  props.selctedItem(event);
                  setChecked(!checked);
                }}
                id={"checkbox_" + props.pkid}
              />
              <span className="checkbox-cnt"></span>
            </label>
          </div>
        ) : (
          <div className="action-cnt">
            <RxCrossCircled
              className="cursor-pointer text-2xl float-right"
              data-pk-id={props.pkid}
              onClick={(event) => {
                props.removeItem(event);
              }}
            />
          </div>
        )}

        {props.page == "placeorder" || props.page == "returnorder" ? (
          <div className="prod-qty">
            <div className="qty-minus">
              <BiMinusCircle
                className="cursor-pointer text-2xl"
                onClick={() => {
                  let newQty =
                    parseInt(itemInQty) > 1 ? parseInt(itemInQty) - 1 : 1;
                  props.updateQty(props.pkid, newQty);
                  setitemInQty(newQty);
                }}
              />
            </div>
            <div className="qty">
              <input
                type="text"
                name={"qty_count_" + props.pkid}
                className="input_qty_box p-2 border rounded"
                data-item-qty={itemInQty}
                value={itemInQty}
                onKeyDown={props.keyPressEvent}
                onChange={(event) => {
                  let up_qty =
                    parseInt(event.target.value) > 0 ? event.target.value : 1;
                  setitemInQty(up_qty);
                  props.updateQty(props.pkid, up_qty);
                }}
              />
            </div>
            <div className="qty-plus">
              <BiPlusCircle
                className="cursor-pointer text-2xl"
                onClick={(e) => {
                  props.updateQty(props.pkid, parseInt(itemInQty) + 1);
                  setitemInQty(parseInt(itemInQty) + 1);
                }}
              />
            </div>
          </div>
        ) : (
          ""
        )}

        <div className="price">
          {curSymbol} {props.fUnitRate}
        </div>
      </div>
    </li>
  );
}
export default ListItem;
