import { useEffect } from "react";
import {
  getVariable,
  clearAll,
  removeVariable,
} from "../../../Helpers/Session";
import Button from "../../../components/Button";
import { useNavigate, NavLink } from "react-router-dom";
function Dashboard(props) {
  let history = useNavigate();
  const logoutAction = () => {
    clearAll();
    history("/login");
  };
  return (
    <div className="w-1/3 m-auto mt-5">
      <div className="Welcome-message flex">
        <h3 className="w-48">Welcome to Dashboard</h3>
        <div className="logout-button w-48">
          <NavLink to="/itemlist">
            <Button
              className="bg-yellow-400 p-2 mr-5 w-22 rounded text-white"
              title="Place Order"
              type="button"
            />
          </NavLink>
          <Button
            className="bg-red-400 p-2 w-22 rounded text-white"
            title="Logout"
            type="button"
            onClick={logoutAction}
          />
        </div>
      </div>
    </div>
  );
}
export default Dashboard;
