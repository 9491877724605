import "./index.css";
import { useState, Fragment } from "react";
import Layout from "./pages/LoginNotRequired/Layout/Layout";
import { checkLogin } from "./Helpers/Session";

function App() {
  const [userProfile, setUserProfile] = useState(checkLogin());
  return (
    <Fragment>
      <Layout userProfile={userProfile} setUserProfile={setUserProfile} />
    </Fragment>
  );
}

export default App;
