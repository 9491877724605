import Button from "../../components/Button";
import { NavLink } from "react-router-dom";
function orderConfirm(props) {
  return (
    <div className="thank-you-page">
      <div className="thank-you-txt">
        <p className="font-medium text-3xl">Thank you for placing the order</p>
      </div>
      <NavLink to="/itemlist">
        <Button
          className="w-24 bg-green-400 p-2 mr-5 rounded text-white shopmorebtn"
          title="Shop More"
          type="Button"
        />
      </NavLink>
      <NavLink to="/my-orders">
        <Button
          className="w-24 bg-blue-400 p-2 mr-5 rounded text-white"
          title="My Orders"
          type="Button"
        />
      </NavLink>
      <NavLink to="/return-orders" className="my-return-list">
        <Button
          className="w-36 bg-blue-400 p-2 mr-5 rounded text-white"
          title="Return Orders"
          type="Button"
        />
      </NavLink>
    </div>
  );
}

export default orderConfirm;
