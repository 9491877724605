import ListItem from "../../components/Listitem";
import { Fragment, useEffect } from "react";
import { getVariable, setVariable } from "../../Helpers/Session";
import { useState } from "react";
import Button from "../../components/Button";
import { NavLink } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import DocumentMeta from "react-document-meta";
function ReturnItemList(props) {
  const [cartTotal, setCartTotal] = useState(0);
  let storeItems = getVariable("returnSelItems", true);
  const [addtoCartItems, setAddtoCartItems] = useState(
    storeItems ? storeItems : []
  );
  const cartItems = getVariable("returnCartItems", true);
  const curSymbol = getVariable("curSymbol", true);
  const itemsArr = getVariable("itemsArr", true);
  const [items, setItems] = useState(itemsArr ? itemsArr : []);
  const [disableButton, setDisableButton] = useState(true);
  const [lastCheckedItem, setLastChecked] = useState(0);
  useEffect(() => {
    {
      let grandTotal = 0;
      let preparedData = {};
      let cartData = [];
      if (addtoCartItems.length > 0) {
        addtoCartItems.map((item, key) => {
          itemsArr.map((value, index) => {
            if (item == value.pkid) {
              grandTotal += parseFloat(value.fUnitRate);
              cartData = cartItems ? cartItems : [];
              if (lastCheckedItem > 0 && value.pkid == lastCheckedItem) {
                preparedData = {
                  pkid: value.pkid,
                  itemTitle: value.itemTitle,
                  product_image: value.product_image,
                  unitMasterName: value.unitMasterName,
                  fUnitRate: value.fUnitRate,
                  cartQuantity: 1,
                };
                cartData.push(preparedData);
              }
            }
          });
        });
        setDisableButton(false);
      } else {
        setDisableButton(true);
      }
      setCartTotal(grandTotal.toFixed(2));
      setVariable("returnCartItems", cartData, true);
      setVariable("returnSelItems", addtoCartItems, true);
    }
  }, [addtoCartItems]);
  const selctedItem = (event) => {
    const selItem = event.target.getAttribute("data-pk-id");
    if (event.target.checked) {
      setLastChecked(selItem);
      const items = [...addtoCartItems, selItem];
      setAddtoCartItems(items);
    } else {
      const uncheckedItems = addtoCartItems.filter((row, index) => {
        return row != selItem;
      });
      setAddtoCartItems(uncheckedItems);
    }
  };

  const onSearch = (event) => {
    let searchterm = event.target.value;
    if (searchterm) {
      const newItems = itemsArr.filter((row, index) => {
        return row.itemTitle.toLowerCase().includes(searchterm.toLowerCase());
      });
      setItems(newItems);
    } else {
      setItems(itemsArr);
    }
  };

  const documentMeta = {
    title: "Return Item List",
  };

  return (
    <div className="product-listing-page">
      <SearchBar onSearch={onSearch} />
      <ul className="product-list">
        {items.length > 0 &&
          items.map((item, index) => {
            return (
              <Fragment key={index}>
                <ListItem
                  {...item}
                  page="returnitemlist"
                  selctedItem={selctedItem}
                  checked={addtoCartItems.includes(item.pkid.toString())}
                />
              </Fragment>
            );
          })}
      </ul>
      <div className="page-footer">
        <div className="summary">
          <label>Return Amount</label>
          <div className="total">
            {curSymbol} {""}
            {cartTotal}
          </div>
        </div>
        <NavLink to="/returnorder">
          <Button
            className="bg-blue-400 p-2 w-40 rounded text-white"
            title="Return Items"
            type="button"
            disabled={disableButton}
          />
        </NavLink>
      </div>
    </div>
  );
}

export default ReturnItemList;
