import { NavLink, useNavigate, useLocation } from "react-router-dom";
import Button from "../components/Button";
import { getVariable, clearAll, setVariable } from "../Helpers/Session";
import logo from "../asset/images/logo.png";
import { Fragment, useState, useEffect } from "react";
import NavBar from "./NavBar";
function Header(props) {
  let companyId = getVariable("companyId", true);
  let companyObj = getVariable("companyObj", true);
  let companyLogo = getVariable("companyLogo", true);
  let yearData = props?.userProfile?.list;
  let user = getVariable("user");
  const curRoute = useLocation();
  const [companyClass, setCompanyClass] = useState(
    curRoute.pathname == "/" || curRoute.pathname == "/company"
      ? "company-selection"
      : "logo"
  );
  const [compLogo, setLogo] = useState(
    curRoute.pathname == "/" || curRoute.pathname == "/company"
      ? logo
      : companyLogo
  );

  useEffect(() => {
    setLogo(
      curRoute.pathname == "/" || curRoute.pathname == "/company"
        ? logo
        : companyLogo
    );
    setCompanyClass(
      curRoute.pathname == "/" || curRoute.pathname == "/company"
        ? "company-selection"
        : "logo"
    );
  });
  let history = useNavigate();
  const logoutAction = () => {
    clearAll();
    history("/login");
  };
  return (
    <Fragment>
      {user && props && props?.userProfile && (
        <div className="page-header">
          <div className="selected-company">
            {compLogo && (
              <div className={companyClass}>
                <img src={compLogo} alt="compLogo" />
              </div>
            )}

            {curRoute.pathname != "/company" && companyId && (
              <div className="company-name">
                <NavLink
                  to={
                    yearData[0]?.company_list?.length > 1
                      ? "/company"
                      : "/itemlist"
                  }
                >
                  {companyObj?.label}
                </NavLink>
              </div>
            )}
            {/* {curRoute.pathname != "/company" && (
              <div className="logout-button">
                <Button
                  className="bg-red-400 p-2 w-22 rounded text-white"
                  title="Logout"
                  type="button"
                  onClick={logoutAction}
                />
              </div>
            )} */}
          </div>
          <div></div>
        </div>
      )}
      <NavBar {...props} logout={logoutAction} />
    </Fragment>
  );
}
export default Header;
