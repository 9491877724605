import Login from "../Login/Login";
import CompanySelection from "../../LoginRequired/CompanySelection/CompanySelectionNew";
import Dashboard from "../../LoginRequired/Dashboard/Dashboard";
import ItemList from "../../LoginRequired/ItemList";
import PlaceOrder from "../../LoginRequired/PlaceOrder";
import orderConfirm from "../../LoginRequired/OrderConfirm";
import OrderListing from "../../LoginRequired/OrderListing";
import OrderDetails from "../../LoginRequired/OrderDetailPage";
import ReturnItemList from "../../LoginRequired/ReturnItemList";
import ReturnOrder from "../../LoginRequired/ReturnOrder";
import ReturnItemListing from "../../LoginRequired/ReturnOrderListing";
import ReturnOrderDetails from "../../LoginRequired/ReturnOrderDetail";
const RoutsArr = [
  {
    title: "Company Selection",
    path: "/company",
    component: CompanySelection,
    isLoginRequired: true,
    className: "company-selection",
    isMenuItem: "",
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
    isLoginRequired: true,
    className: "",
    isMenuItem: "",
  },
  {
    title: "Login",
    path: "/login",
    component: Login,
    isLoginRequired: false,
    className: "login-page",
    isMenuItem: "",
  },
  {
    title: "Place Order",
    path: "/itemlist",
    component: ItemList,
    isLoginRequired: true,
    className: "item-list",
    isMenuItem: "yes",
  },
  {
    title: "Place Order",
    path: "/placeorder",
    component: PlaceOrder,
    isLoginRequired: true,
    className: "place-order",
    isMenuItem: "",
  },
  {
    title: "Login",
    path: "/",
    component: Login,
    isLoginRequired: false,
    className: "login-page",
    isMenuItem: "",
  },
  {
    title: "Thank you",
    path: "/thank-you",
    component: orderConfirm,
    isLoginRequired: true,
    className: "thank-you",
    isMenuItem: "",
  },
  {
    title: "My Orders",
    path: "/my-orders",
    component: OrderListing,
    isLoginRequired: true,
    className: "my-order-list",
    isMenuItem: "yes",
  },
  {
    title: "Order Details",
    path: "/order-detail/:id",
    component: OrderDetails,
    isLoginRequired: true,
    className: "order-details",
    isMenuItem: "",
  },
  {
    title: "Place Return Order",
    path: "/return-items",
    component: ReturnItemList,
    isLoginRequired: true,
    className: "return-list",
    isMenuItem: "yes",
  },
  {
    title: "Return Order",
    path: "/returnorder",
    component: ReturnOrder,
    isLoginRequired: true,
    className: "return-order",
    isMenuItem: "",
  },
  {
    title: "My Return Orders",
    path: "/return-orders",
    component: ReturnItemListing,
    isLoginRequired: true,
    className: "my-return-list",
    isMenuItem: "yes",
  },
  {
    title: "Return Order Details",
    path: "/return-order-detail/:id",
    component: ReturnOrderDetails,
    isLoginRequired: true,
    className: "return-order-details",
    isMenuItem: "",
  },
];

export default RoutsArr;
