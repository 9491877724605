import { useParams, useNavigate, NavLink } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import { getVariable } from "../../Helpers/Session";
import ReactToast from "../../components/Toast";
import apiRequest from "../../Api";
import Button from "../../components/Button";
function OrderDetails(props) {
  let { id } = useParams();
  const curSymbol = getVariable("curSymbol", true);
  const [orderDetails, setOrderDetails] = useState([]);
  const [orderTotal, setOrderTotal] = useState(0);
  let history = useNavigate();
  useEffect(() => {
    if (!id) {
      history("/my-orders");
    }
  });

  useEffect(() => {
    CallOrderDetailApi();
  }, []);
  const CallOrderDetailApi = async () => {
    try {
      const url = process.env.REACT_APP_API_BASE;
      let apiSetting = {
        method: "POST",
        apiParams: { order_id: id },
      };
      let listingData = await apiRequest(
        `${url}OrderPanelApi/orderDetail`,
        apiSetting
      );
      if (listingData?.data && listingData.data.success == 1) {
        setOrderDetails(listingData.data.data);
        setOrderTotal(listingData.data.data[0].net_amount);
      } else {
        throw new Error(listingData.data.message);
      }
    } catch (error) {
      ReactToast({ type: "error", message: error.message });
      setTimeout(function () {
        history("/my-orders");
      }, 1000);
    } finally {
    }
  };

  let roundAmount = (amount, floatingPoints) => {
    return parseFloat(amount).toFixed(floatingPoints);
  };

  return (
    <div className="order-detail-page">
      <div className="order-detail-tbl-wrap">
        {orderDetails.length > 0 && (
          <div className="ord_info_wrap">
            <div className="ord_no">{orderDetails[0]["invoice_no"]}</div>
            <div className="ord_dt">{orderDetails[0]["order_date"]}</div>
          </div>
        )}
        <table width="100%" cellPadding="5">
          <thead>
            <tr>
              <th>Item</th>
              <th className="qty_head">Qty</th>
              <th className="ta-right">Rate</th>
              <th className="ta-right">Amount</th>
            </tr>
          </thead>
          <tbody>
            {orderDetails.length > 0 &&
              orderDetails.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      {item.item_title}
                      {item.unit_title && (
                        <p className="unit_rate_txt">
                          Rate in {item.unit_title}
                        </p>
                      )}
                    </td>
                    <td align="center">{item.item_wise_qty}</td>

                    <td align="right">{roundAmount(item.unit_price, 2)}</td>
                    <td align="right">{roundAmount(item.total_amount, 2)}</td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>

      {orderDetails.length > 0 && orderDetails && (
        <div className="page-footer">
          <div className="summary">
            <label>Order Amount</label>
            <div className="total">
              {curSymbol}
              {orderTotal}
            </div>
          </div>
          <NavLink to="/my-orders">
            <Button
              className="bg-red-400 p-2 rounded text-white"
              title="Back"
              type="button"
            />
          </NavLink>
        </div>
      )}
    </div>
  );
}

export default OrderDetails;
