import {
  getVariable,
  setVariable,
  removeVariable,
} from "../../../Helpers/Session";

import { useEffect, useState, useRef } from "react";
import apiRequest from "../../../Api";
import { useNavigate } from "react-router";
import ReactToast from "../../../components/Toast";
import Button from "../../../components/Button";
function CompanySelectionNew(props) {
  const [companyData, setCompanyData] = useState([]);
  const history = useNavigate();
  const [isApiCalling, setIsApiCalling] = useState(false);
  const [cur_company_id, setCurCompanyId] = useState(
    getVariable("companyId", true)
  );
  const [selCompanyId, setSelCompanyId] = useState(
    getVariable("companyId", true)
  );
  useEffect(() => {
    if (!props.userProfile) {
      history("/login");
    }
    let yearData = props?.userProfile?.list;
    if (yearData) {
      setCompanyData(yearData[0].company_list);
      setVariable(
        "yearObj",
        { value: yearData[0].id, label: yearData[0].years },
        true
      );
      setVariable("yearId", yearData[0].id, true);
    }
  }, []);

  const checkedCompany = async (event) => {
    if (event.target.checked) {
      let company_id = event.target.getAttribute("data-pk-id");
      let company_name = event.target.getAttribute("data-company-name");
      let companyObj = { value: company_id, label: company_name };
      setVariable("companyObj", companyObj, true);
      setVariable("companyId", company_id, true);
      setSelCompanyId(company_id);
    }
  };

  const companySelected = async (e) => {
    try {
      e.preventDefault();
      if (!selCompanyId) {
        throw new Error("Please Select company");
      }
      setIsApiCalling(true);
      if (cur_company_id != selCompanyId) {
        let baseUrl = process.env.REACT_APP_API_BASE;
        const apiSetting = {
          method: "POST",
          apiParams: { method: "posSaleView" },
        };
        const itemData = await apiRequest(
          `${baseUrl}OrderPanelApi/index`,
          apiSetting
        );
        if (itemData.data.success == 1) {
          removeVariable("selItems", true);
          removeVariable("cartItems", true);
          removeVariable("returnSelItems", true);
          removeVariable("returnCartItems", true);

          setVariable("itemsArr", itemData.data.data.items_data.data, true);
          setVariable("curSymbol", itemData.data.data.cur_symbol, true);
          setVariable("companyLogo", itemData.data.data.company_logo, true);
          history("/itemlist");
        } else {
          throw new Error(itemData.data.message);
        }
      } else {
        history("/itemlist");
      }
    } catch (error) {
      ReactToast({ type: "error", message: error.message });
    } finally {
      setIsApiCalling(false);
    }
  };

  return (
    <div className="company-selection-cnt">
      <h5> Company Selection </h5>
      <ul className="company-list">
        {companyData?.length > 0 &&
          companyData.map((data, index) => {
            return (
              <li key={index}>
                {data.company_logo && (
                  <div className="company-selection-logo">
                    <img src={data.company_logo} alt="logo" />
                  </div>
                )}
                <div className="company-name">{data.companyName}</div>
                <div className="select-cnt">
                  <label className="custom-checkbox">
                    <input
                      type="radio"
                      data-pk-id={data.id}
                      data-company-name={data.companyName}
                      onChange={checkedCompany}
                      name="company-name"
                      checked={cur_company_id == data.id ? true : undefined}
                    />
                    <span className="checkbox-cnt"></span>
                  </label>
                </div>
              </li>
            );
          })}
      </ul>
      <Button
        className="bg-blue-400 p-2 w-40 rounded text-white company-sel-submit-btn"
        title="Submit"
        type="button"
        disabled={isApiCalling}
        onClick={companySelected}
      />
    </div>
  );
}

export default CompanySelectionNew;
