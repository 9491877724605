import { createContext, useState } from "react";
const MasterContext = createContext();

const NavigationContext = (props) => {
  const [companyData, setCompanyData] = useState([]);
  return (
    <MasterContext.Provider value={{ companyData, setCompanyData }}>
      {props.children}
    </MasterContext.Provider>
  );
};

export { MasterContext };
export default NavigationContext;
