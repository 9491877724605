var encKey = process.env.REACT_APP_ENC_KEY;
var storagePrefix = "cust_portal_";
const setVariable = (name, value, isLocal) => {
  let new_name = storagePrefix + name;
  if (value == "" || value == null || value == []) {
    removeVariable(name, isLocal);
  } else if (isLocal) {
    localStorage.setItem(new_name, JSON.stringify(value));
  } else {
    sessionStorage.setItem(new_name, JSON.stringify(value));
  }
};

const removeVariable = (name, isLocal) => {
  name = storagePrefix + name;
  if (isLocal) {
    localStorage.removeItem(name);
  } else {
    sessionStorage.removeItem(name);
  }
};

const clearAll = () => {
  sessionStorage.clear();
  localStorage.clear();
};

const getVariable = (name, isLocal) => {
  name = storagePrefix + name;
  if (isLocal) {
    return JSON.parse(localStorage.getItem(name));
  } else {
    return JSON.parse(sessionStorage.getItem(name));
  }
};

const checkLogin = () => {
  const rememberMe = getVariable("rememberMe", true);
  if (rememberMe) {
    setVariable("user", JSON.stringify(getVariable("user", true)));
    return getVariable("user", true);
  } else {
    return getVariable("user");
  }
};

export { setVariable, removeVariable, clearAll, getVariable, checkLogin };
