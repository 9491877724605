import { useState, useEffect, Fragment } from "react";
import apiRequest from "../../Api";
import BasicTable from "../../components/ListingTable";
import ReactToast from "../../components/Toast";
import SearchBar from "../../components/SearchBar";
function ReturnItemListing(props) {
  const [orderList, setOrderList] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    if (isLoading) {
      CallAPiList();
    }
  }, [orderList]);

  const onSearch = (event) => {
    let searchterm = event.target.value;

    if (searchterm) {
      const newItems = orderList.filter((row, index) => {
        return (
          row.invoice_no.toLowerCase().includes(searchterm.toLowerCase()) ||
          row.order_date.toLowerCase().includes(searchterm.toLowerCase()) ||
          row.net_amount.toLowerCase().includes(searchterm.toLowerCase())
        );
      });
      setFilterData(newItems);
    } else {
      setFilterData(orderList);
    }
  };

  let headerData = [
    {
      label: "Order No",
      render: (data) => data.invoice_no,
      className: "text-left",
      addLink: "yes",
      urlLink: "return-order-detail/",
      renderLink: (data) => data.master_id,
    },
    {
      label: "Qty",
      render: (data) => data.item_qty,
      className: "text-center",
    },
    {
      label: "Order Date",
      render: (data) => data.order_date,
      className: "text-left",
    },
    {
      label: "Net Amount",
      render: (data) => data.net_amount,
      className: "text-right",
    },
  ];

  const CallAPiList = async () => {
    try {
      const url = process.env.REACT_APP_API_BASE;
      let apiSetting = {
        method: "POST",
        apiParams: {
          order_type: "return_order",
        },
      };
      let listingData = await apiRequest(
        `${url}OrderPanelApi/orderListing`,
        apiSetting
      );
      if (listingData?.data && listingData.data.success == 1) {
        setOrderList(listingData.data.data);
        setFilterData(listingData.data.data);
      } else {
        throw new Error(listingData.data.message);
      }
    } catch (error) {
      ReactToast({ type: "error", message: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Fragment>
      <SearchBar onSearch={onSearch} />
      <BasicTable headers={headerData} data={filterData} />
    </Fragment>
  );
}

export default ReturnItemListing;
